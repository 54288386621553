<template>
  <div class="exam-date">
    <div v-if="!displayValues.length" class="py-4 pr-8 text-center">
      {{ $t('calendar.exams-empty') }}
    </div>
    <div v-else v-for="(element, index) in displayValues" :key="index" class="pr-sm md:pr-md lg:pr-xl">
      <DateTime :date="element[0]" format="weekdayLong" class="font-bold" />
      <span>,</span>
      <DateTime :date="element[0]" format="mediumDate" class="font-bold" />
      <CalendarEvent
        v-for="(entry, index) in element[1]"
        :key="index"
        :entry="entry"
        class="my-2"
        @doAction="(comp: string, data?: ActionDataDto) => emits('doAction', comp, data)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { CalendarDto } from '@/types/contracts/generated/models/calendarDto';
import type { ActionDataDto } from '@/types/contracts/generated/models/actionDataDto';
import { EventType } from '@/types/contracts/generated/models/eventType';

export interface ExamDatesProps {
  /**
   * Calendar data object
   */
  calendar: CalendarDto;
}

export interface ExamDatesEmits {
  /**
   * DoAction event handler
   */
  (ev: 'doAction', comp: string, data?: ActionDataDto): void;
}
</script>

<script setup lang="ts">
import { ref, watch } from 'vue';
import DateTime from '@/components/common/DateTime.vue';
import CalendarEvent from '@/components/appointments/partials/CalendarEvent.vue';

// Define Component
const props = defineProps<ExamDatesProps>();
const emits = defineEmits<ExamDatesEmits>();

// States
const displayValues = ref();

/**
 * Initial selected Date
 */
watch(
  props,
  () => {
    displayValues.value = Object.entries(props.calendar.todos ?? {});
  },
  { immediate: true }
);
</script>

<style scoped>
.exam-date {
  @apply flex flex-col pl-2 py-4 pt-2 overflow-y-auto space-y-sm scroll-m-4 h-full w-full pb-6;

  @screen md {
    @apply pl-5;
  }

  @screen lg {
    @apply pl-8;
  }
}

.exam-event {
  @apply bg-white pr-4 px-4 py-4 my-2 border border-solid border-zinc-100 rounded-md;

  @screen md {
    @apply pr-5;
  }

  @screen lg {
    @apply pr-8;
  }
}
</style>
