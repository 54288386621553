<template>
  <PrimeFileUpload mode="advanced" @select="addSelected" :accept="accept ?? undefined" :multiple="multiple ?? false">
    <template #header="{ chooseCallback }">
      <SecondaryButton size="small" :label="$t('support.dialog.select')" @click="chooseCallback()" />
    </template>

    <template #content="{ files, removeFileCallback }">
      <div v-if="files.length > 0">
        <div class="flex flex-wrap items-center justify-center gap-5 p-0 sm:p-5">
          <div
            v-for="(file, index) of files"
            :key="file.name + file.type + file.size"
            class="flex flex-col items-center gap-2 m-0 card border-1 border-surface-500"
          >
            <div v-if="file.type.startsWith('image')">
              <!-- @vue-ignore -->
              <img
                role="presentation"
                :alt="file.name"
                :src="file.objectURL"
                width="100"
                height="50"
                class="shadow-2"
              />
            </div>
            <div v-else-if="file.type == 'application/pdf'">
              <IconPDF width="48" height="48" />
            </div>
            <div v-else>
              <IconZip width="48" height="48" />
            </div>
            <div class="flex items-center gap-2">
              <span class="font-semibold">{{ file.name }}</span>
              <DangerButton :icon="IconTrashCanOutline" @click="removeSelected(index, removeFileCallback)" rounded />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #empty>
      <div class="empty">
        <IconCloudUploadOutline :width="64" :height="64" />
        <span>{{ $t('support.dialog.files') }}</span>
      </div>
    </template>
  </PrimeFileUpload>
</template>

<script lang="ts">
import type { MaybeRef } from 'vue';

export interface UploadFieldProps {
  /**
   * A unique field id, may already be generated by the parent FormControl component.
   */
  id: string;

  /**
   * The desired field value.
   */
  modelValue?: File[];

  /**
   * The desired field name.
   */
  name?: MaybeRef<string> | string;

  /**
   * The field disabled state.
   */
  disabled?: boolean;

  /**
   * The field required state.
   */
  required?: boolean;

  /**
   * The field invalid state.
   */
  invalid?: boolean;
  accept?: string;
  multiple?: boolean;
}

export interface UploadFieldEmits {
  /**
   * Update model value handler.
   */
  (ev: 'update:modelValue', value: File[]): void;
}
</script>

<script lang="ts" setup>
import IconCloudUploadOutline from '~icons/mdi/cloud-upload-outline';
import IconTrashCanOutline from '~icons/mdi/trash-can-outline';
import IconPDF from '~icons/mdi/file-pdf-box';
import IconZip from '~icons/mdi/folder-zip-outline';
import PrimeFileUpload, { type FileUploadSelectEvent } from 'primevue/fileupload';
import { computed } from 'vue';

import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import DangerButton from '@/components/buttons/DangerButton.vue';

// Define Component
const props = defineProps<UploadFieldProps>();
const emits = defineEmits<UploadFieldEmits>();

// States
const value = computed({
  get() {
    return props.modelValue || [];
  },
  set(value) {
    emits('update:modelValue', value);
  }
});

/**
 * Add file
 * @param event
 */
function addSelected(event: FileUploadSelectEvent) {
  const files = [...value.value];
  value.value = event.files;
}

/**
 * Remove file
 * @param index
 * @param removeFileCallback
 */
function removeSelected(idx: number, removeFileCallback: (idx: number) => void): void {
  removeFileCallback(idx);

  const files = [...value.value];
  value.value = files;
}
</script>

<style scoped>
.empty {
  @apply w-full flex flex-col items-center justify-center pointer-events-none select-none;
  @apply text-zinc-400;

  & span {
    @apply text-sm font-semibold;
  }
}
</style>
