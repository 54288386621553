<template>
  <PrimeButton
    :severity="props.severity"
    :size="props.size || void 0"
    :label="props.label || void 0"
    :icon="typeof props.icon == 'string' ? props.icon : void 0"
    :iconPos="props.iconPos || void 0"
    @click="(ev) => emits('click', ev)"
    :class="props.class"
    :disabled="props.disabled || void 0"
    :loading="props.loading || void 0"
    :rounded="rounded || void 0"
  >
    <template v-if="(props.icon && typeof props.icon !== 'string')" #icon>
      <component
        :is="props.icon"
        :width="iconSize"
        :height="iconSize"
        :class="{
          'mt-3': props.size != 'small' && props.iconPos == 'bottom',
          'mr-3': props.size != 'small' && props.iconPos == 'left',
          'mb-3': props.size != 'small' && props.iconPos == 'top',
          'ml-3': props.size != 'small' && props.iconPos == 'right',
          'mt-2': props.size == 'small' && props.iconPos == 'bottom',
          'mr-2': props.size == 'small' && props.iconPos == 'left',
          'mb-2': props.size == 'small' && props.iconPos == 'top',
          'ml-2': props.size == 'small' && props.iconPos == 'right',
          'p-button-icon-top': props.iconPos == 'top',
          'p-button-icon-left': props.iconPos == 'left',
          'p-button-icon-right': props.iconPos == 'right',
          'p-button-icon-bottom': props.iconPos == 'bottom'
        }"
      />
    </template>
  </PrimeButton>
</template>

<script lang="ts">
import type { Component } from 'vue';
import type {StyleDto} from "@/types/contracts/generated/models/styleDto";

export interface ButtonProps {
  /**
   * The desired PrimeVue button label.
   */
  label?: string | null;

  /**
   * Additional PrimeVue button icon component, or legacy PrimeIcon string.
   */
  icon?: Component | string | null;

  /**
   * Additional PrimeVue button icon position.
   */
  iconPos?: 'left' | 'top' | 'bottom' | 'right';

  /**
   * Additional size used for the button icon.
   */
  iconSize?: number;

  /**
   * The desired PrimeVue button severity.
   */
  severity?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger' | 'help' | StyleDto;

  /**
   * The desired PrimeVue button size.
   */
  size?: 'small' | 'large';

  /**
   * Additional class names for the PrimeVue button component.
   */
  class?: string | string[];

  /**
   * Fully rounded button.
   */
  rounded?: boolean;

  /**
   * Button loading date.
   */
  loading?: boolean;

  /**
   * Button disabled date.
   */
  disabled?: boolean;
}

export interface ButtonEmits {
  /**
   * Click Event Handler
   * @param event
   */
  (ev: 'click', event: Event): void;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'ButtonStd'
}
</script>

<script lang="ts" setup>
import PrimeButton from 'primevue/button';

// Define Component
const props = withDefaults(defineProps<ButtonProps>(), {
  iconPos: 'left',
  iconSize: 16
});
const emits = defineEmits<ButtonEmits>();
</script>

<style scoped>
.p-button {
  @apply duration-300 ease-in-out transition-colors font-fom-fonts;
}

.p-button.p-disabled:disabled {
  @apply pointer-events-auto cursor-default;
}

.p-button .p-button-label {
  @apply transition-none;
}

.p-button.p-button-icon-only {
  @apply gap-0;
  width: 38px;
  height: 38px;

  &.p-button-sm {
    width: 32px;
    height: 32px;
  }

  & svg {
    @apply !m-0;
  }

  & .p-button-label {
    @apply hidden;
  }
}
</style>
