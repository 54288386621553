<template>
  <span>{{ $d(date, props.format) }}</span>
</template>

<script lang="ts">
export interface DateTimeProps {
  /**
   * A date object or date/time string.
   */
  date: Date | string;

  /**
   * One of the declared format to use.
   */
  format: string;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'DateTime'
}
</script>

<script lang="ts" setup>
import { computed } from 'vue';

// Define Component
const props = defineProps<DateTimeProps>();

// States
const date = computed(() => {
  if (props.date instanceof Date) {
    return props.date;
  } else if (props.date.length === 10) {
    let hackedDate = new Date();
    const tmpDate = new Date(props.date);
    hackedDate.setDate(tmpDate.getDate());
    hackedDate.setMonth(tmpDate.getMonth());
    hackedDate.setFullYear(tmpDate.getFullYear())
    hackedDate.setHours(0);
    hackedDate.setMinutes(0);
    hackedDate.setMilliseconds(0);
    let hackedString = Intl.DateTimeFormat('de-DE', {hour12: false, hour: '2-digit', minute: '2-digit', timeZoneName: 'longOffset'}).format(hackedDate).replace(' GMT', '');
    hackedDate = new Date(props.date + 'T' + hackedString);
    return new Date(hackedDate);
  } else {
    return new Date(props.date);
  }
});
</script>

<style scoped></style>
