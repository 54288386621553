/**
 * [netzfactor] BCW Core Services API (dev)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * Contact: app@netzfactor.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EventType = 'PRUEFUNG' | 'VORLESUNG' | 'WEBINAR';

export const EventType = {
    Pruefung: 'PRUEFUNG' as EventType,
    Vorlesung: 'VORLESUNG' as EventType,
    Webinar: 'WEBINAR' as EventType
};

