import { defineStore } from 'pinia';

interface ProcessingFile {
  uuid: string;
  progress: string;
}

interface State {
  processingFiles: ProcessingFile[];
}

export const useVideoProcessingStore = defineStore('videoProcessingProgress', {
  state: (): State => ({
    processingFiles: []
  }),
  getters: {},
  actions: {
    updateFile(id: string, progress: string): void {
      const searchResult = this.processingFiles.find((entry) => entry.uuid === id);
      if (searchResult) {
        searchResult.progress = progress;
      } else {
        this.processingFiles.push({ uuid: id, progress: progress });
      }
    }
  }
});
