<template>
    <Dialog v-model:visible="visible" class="w-[95vw]" :class="[
        profile ? 'hidden' : (loading ? '' : 'no-content')
    ]" modal :draggable="false" @hide="emit('close')">

        <template #header>
            <PersonComponent :person-id="props.personId"
                :options="{ image: true, isInModal: true, socials: profile?.kommunikation, isBuddy: !!buddyList.find(buddy => buddy.id === personId) }" />
        </template>

        <template #default>
            <PublicProfileBase v-if="profile" :profile="profile" :options="{ displayLecture: options.displayLecture, isDialog: true }" />
            <Skeleton v-else-if="!profile && loading" />
        </template>

        <template #footer>
            <div class="flex flex-col justify-end gap-2 md:flex-row">
                <RouterLink v-if="profile" :to="{ name: 'PersonDetail', params: { personId: personId } }"
                    class="justify-center inline-block p-button p-component p-button-secondary hover:bg-primary-600 hover:text-white">
                    {{ t('public_profile.button.additional_info') }}
                </RouterLink>
                <RouterLink :to="{ name: 'messages-new' }"
                    class="justify-center p-button p-component p-button-primary hover:bg-primary-600 hover:text-white"
                    @click="directMessageRecipient = getById(personId) ?? null">
                    {{ t('public_profile.button.send_message') }}
                </RouterLink>
            </div>
        </template>
    </Dialog>
</template>

<script lang="ts">
// Default Export, used for IDE auto-importing only
export default {
  name: 'PublicProfileDialog'
}
</script>

<script lang="ts" setup>
import type { ProfilzusatzDto } from "@/types/contracts/generated/models/profilzusatzDto";

import { storeToRefs } from "pinia";
import Dialog from "primevue/dialog";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Skeleton from 'primevue/skeleton';
import PersonComponent from "@/components/shared/PersonComponent.vue";
import PublicProfileBase from "@/components/shared/PublicProfileBase.vue";
import { usePerson } from "@/composables/http/use-person";
import { useInfoStore } from "@/stores/info";
import { usePersonStore } from "@/stores/person";

const props = defineProps<{
    visible: boolean
    personId: number,
    options: {
        displayLecture: boolean,
    }
}>();

const emit = defineEmits<{
    close: []
}>();

const { getPersonProfile } = usePerson();
const { getById } = usePersonStore();
const profile = ref<ProfilzusatzDto>();
const loading = ref<boolean>();
const { buddyList, directMessageRecipient } = storeToRefs(useInfoStore());

const visible = ref(props.visible);
watch(props, () => {
    visible.value = props.visible;
    if (visible.value === true) {
        loading.value = true;
        getPersonProfile(props.personId).then(data => {
            profile.value = data;
        }).catch(err => console.error(err)).finally(() => {
            loading.value = false;
        })
    }
}, { immediate: true });

const { t } = useI18n();

</script>

<style>
/* quickfix no scope 'no-content' wird eh nur in dieser Komponente gesetzt */
.p-dialog.no-content .p-dialog-header,
.p-dialog.no-content .p-dialog-footer
 {
    @apply border-transparent;
}

.p-dialog.no-content .p-dialog-content
 {
    @apply !hidden;
}
</style>
