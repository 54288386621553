<template>
  <div class="calendar">
    <Calendar
      :inline="true"
      :selectOtherMonths="true"
      class="calendar-input"
      v-model="date"
      @dateSelect="displayDate($event)"
    >
      <template #date="slotProps">
        <span :class="[hasEvent(slotProps.date)]" class="calendar-number">
          {{ slotProps.date.day }}
        </span>
      </template>
    </Calendar>

    <div class="flex justify-end lg:px-8">
      <div class="calendar-subscribe" :title="t('calendar.abo_button')" @click="showDialog = true">
        {{ t('calendar.abo_button') }}
      </div>
    </div>

    <div class="calendar-events">
      <div v-if="selectedEvents.length > 0" class="calendar-mask">
        <DateTime :date="date" format="dd-MM" />
      </div>

      <CalendarEvent
        v-for="entry in selectedEvents"
        :entry="entry"
        :key="entry.uuid"
        @doAction="(comp: string, data?: ActionDataDto) => emit('doAction', comp, data)"
      />
    </div>
  </div>

  <Dialog v-model:visible="showDialog" modal :draggable="false" header="Kalender exportieren" @cancel="showDialog = false"
  :pt="{
    root: {
      class: '!w-[calc(100%-2rem)] lg:!w-full !max-w-lg'
    },
    mask: {
      class: '!z-[10001]'
    }
  }">
    <template #default>
      <div>
        <div class="flex flex-col gap-2">
            <a v-if="calendar?.pdf" @click.prevent="downloadItem(calendar.pdf)" class="flex items-center gap-2 mb-2">
              <!-- {{ $t('application.cancel_confirm_modal.text') }} -->
              <IconFilePdfBox />
              {{ $t('calendar.export_pdf') }}
            </a>
            <a :href="calendar?.ical" class="flex items-center gap-2 mb-2">
              <!-- {{ $t('application.cancel_confirm_modal.text') }} -->
              <IconCalendarMonth />
              {{ $t('calendar.export_webcall') }}
            </a>
            <a :href="webcalToHttp" class="flex items-center gap-2 mb-2">
              <!-- {{ $t('application.cancel_confirm_modal.text') }} -->
              <IconCalendarMonth />
              {{ $t('calendar.export_https') }}
            </a>
        </div>
      </div>
    </template>
    <template #footer>
      <SecondaryButton :disabled="false" label="Schließen" @click="showDialog = false"/>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { EventType } from '@/types/contracts/generated/models/eventType';
import type { CalendarDto } from '@/types//contracts/generated/models/calendarDto';
import type { EventDto3 } from '@/types/contracts/generated/models/eventDto3';
import type { ActionDataDto } from '@/types/contracts/generated/models/actionDataDto';
import Dialog from 'primevue/dialog';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import IconFilePdfBox from '~icons/mdi/file-pdf-box';
import IconCalendarMonth from '~icons/mdi/calendar-month';
</script>

<script setup lang="ts">
import Calendar from 'primevue/calendar';
import { useI18n } from 'vue-i18n';
import { ref, watchEffect } from 'vue';

import DateTime from '@/components/common/DateTime.vue';
import CalendarEvent from '@/components/appointments/partials/CalendarEvent.vue';
import {useDownload} from "@/composables/use-download";

const { t } = useI18n();
const date = ref<Date>(new Date());
const selectedEvents = ref<EventDto3[]>([]);
const {downloadItem} = useDownload();

const props = defineProps<{
  calendar: CalendarDto;
}>();

const emit = defineEmits<{
  doAction: [comp: string, data?: ActionDataDto];
}>();

const showDialog = ref(false)

const webcalToHttp = ref(props.calendar?.ical?.replace('webcal', 'https') || '#');

const displayDate = (date: Date) => {
  if (props.calendar.termine) {
    const datestring = `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    }-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
    selectedEvents.value = props.calendar.termine[datestring] ? props.calendar.termine[datestring].filter(entry => !entry.onlysemesterplan) : [];
  }
};

watchEffect(() => {
  if (props.calendar) {
    displayDate(date.value);
    watchEffect(() => {}); // Unwatch
  }
});

const hasEvent = (date: any): string => {
  if (props.calendar.termine) {
    // format to YYYY-MM-DD
    const datestring = `${date.year}-${date.month + 1 < 10 ? '0' + (date.month + 1) : date.month + 1}-${
      date.day < 10 ? '0' + date.day : date.day
    }`;

    if (props.calendar.termine[datestring] && props.calendar.termine[datestring]) {
      if (
        props.calendar.termine[datestring].some((entry) => entry.type === EventType.Pruefung && !entry.onlysemesterplan) &&
        props.calendar.termine[datestring].some(
          (entry) => (entry.type === EventType.Vorlesung || entry.type === EventType.Webinar) && !entry.onlysemesterplan
        )
      ) {
        return 'has-both';
      } else if (props.calendar.termine[datestring].some((entry) => entry.type === EventType.Pruefung && !entry.onlysemesterplan)) {
        return 'has-exam';
      } else if (props.calendar.termine[datestring].some((entry) => (entry.type === EventType.Vorlesung || entry.type === EventType.Webinar) && !entry.onlysemesterplan)) {
        return 'has-event';
      }
    }
    return '';
  }
  return '';
};
</script>

<style scoped>
.p-highlight .has-events {
  &:after {
    background-color: var(--primary-700);
    opacity: 1;
  }
}

:deep(.p-datepicker) {
  width: 100% !important;
}

.calendar {
  @apply flex flex-col flex-1 overflow-y-auto lg:overflow-hidden;

  &-number {
    &:hover {
      @apply !bg-transparent;
    }
  }

  &-input {
    @apply w-full;

    @screen md {
      @apply px-md;
    }

    @screen lg {
      @apply px-xl;
    }
  }

  &-subscribe {
    @apply text-sm font-semibold uppercase cursor-pointer text-primary-700 mt-xs py-sm pl-sm;

    &:hover {
      @apply underline;
    }

    @screen md {
      @apply px-xs;
    }

    @screen lg {
      @apply px-xs;
    }
  }

  &-events {
    @apply flex flex-col h-auto gap-2 py-4 pt-0 lg:overflow-y-auto scroll-m-4 lg:px-sm;

    @screen md {
      @apply px-md;
    }

    @screen lg {
      @apply px-xl;
    }
  }

  &-mask {
    @apply sticky top-0 pt-1 pb-1 text-lg font-bold bg-zinc-50 z-10;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, transparent 100%);
  }

  .has-exam,
  .has-both,
  .has-event {
    @apply relative;

    &:after {
      @apply absolute rounded-full;
      width: 6px;
      height: 6px;
      content: '';
    }
  }

  .has-exam {
    &:after {
      transform: translate(-50%, 0);
      @apply bg-accent-150 top-full left-1/2;
    }
  }

  .has-event {
    &:after {
      @apply top-full left-1/2 bg-primary-700;
      transform: translate(-50%, 0);
    }
  }

  .has-both {
    &:after {
      @apply bg-primary-700 top-full;
      left: 33.33%;
      transform: translate(-66.66%, 0);
    }

    &:before {
      @apply absolute top-full rounded-full bg-accent-150;
      width: 6px;
      height: 6px;
      left: 66.66%;
      transform: translate(-33.33%, 0);
      content: '';
    }
  }
}
</style>
