<template>
  <div class="public-profile-base" :class="props.options.isDialog && 'public-profile-base-is-dialog'">
    <template v-if="props.options.isDialog">
      <div
        v-if="props.options && props.options.displayLecture && profile.fachgebiet || (profile.kompetenzfelder && profile.kompetenzfelder.length > 0)"
        class="flex flex-row gap-6 mb-4">
        <IconSchoolOutline class="text-xl text-primary shrink-0 " />
        <div>
          <div v-if="profile.fachgebiet">
            <div class="mb-2 font-semibold">
              {{ t('public_profile.headers.field') }}
            </div>
            <ul class="m-0">
              <li>
                {{ profile.fachgebiet }}
              </li>
            </ul>
          </div>
          <div v-if="profile.kompetenzfelder && profile.kompetenzfelder.length > 0">
            <div class="mt-4 mb-2 font-semibold">
              {{ t('public_profile.headers.areas_of_expertise') }}
            </div>
            <ul class="m-0">
              <li v-for="(entry, index) in profile.kompetenzfelder" :key="index">
                {{ entry }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
    <div class="flex flex-row gap-8 mb-4" :class="props.options.isDialog ?? 'mt-6'"
      v-if="profile.kommunikation && profile.kommunikation.filter(entry => entry.typ === Kommunikationsfeldtyp.EMail).length > 0">
      <IconEmail v-if="props.options.isDialog" class="w-8 h-8 fill-primary shrink-0" />
      <div>
        <div
          v-for="(entry, index) in profile.kommunikation.filter(element => element.typ === Kommunikationsfeldtyp.EMail)"
          :key="index" class="mb-4 last:mb-0">
          <div>
            <span class="public-profile-base-label">{{ entry.name }}</span>
          </div>
          <a :href="`mailto:${entry.value}`" class="public-profile-base-value">{{ entry.value }}</a>
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-8" :class="props.options.isDialog ? 'mt-6' : 'mb-6'"
      v-if="profile.kommunikation && profile.kommunikation.filter(entry => entry.typ === Kommunikationsfeldtyp.Phone || entry.typ === Kommunikationsfeldtyp.Mobile).length > 0">
      <IconPhoneMessage v-if="props.options.isDialog" class="w-8 h-8 fill-primary shrink-0" />
      <div>
        <div
          v-for="(entry, index) in profile.kommunikation.filter(element => element.typ === Kommunikationsfeldtyp.Phone)"
          :key="index" class="mb-4 last:mb-0">
          <div>
            <span class="public-profile-base-label">{{ entry.name }}</span>
          </div>
          <a :href="`tel:${entry.value}`" class="public-profile-base-value">{{ entry.value }}</a>
        </div>
        <div
          v-for="(entry, index) in profile.kommunikation.filter(element => element.typ === Kommunikationsfeldtyp.Mobile)"
          :key="index" class="mb-2 last:mb-0">
          <div>
            <span class="public-profile-base-label">{{ entry.name }}</span>
          </div>
          <a :href="`tel:${entry.value}`" class="public-profile-base-value">{{ entry.value }}</a>
        </div>
      </div>
    </div>

    <RouterLink v-if="!props.options.isDialog" :to="{ name: 'messages-new' }" class="w-full justify-center p-button bg-accent-100 hover:!bg-accent-150 p-component text-zinc-900"
      @click="$emit(directMessageRecipient)">
      <IconChatOutline class="mr-1 text-sm" />
      {{ $t('public_profile.button.send_message') }}
    </RouterLink>

  </div>
</template>

<script lang="ts">
// Default Export, used for IDE auto-importing only
export default {
  name: 'PublicProfileBase'
}
</script>

<script setup lang="ts">
import type { ProfilzusatzDto } from "@/types/contracts/generated/models/profilzusatzDto";
import { Kommunikationsfeldtyp } from "@/types/contracts/generated/models/kommunikationsfeldtyp";
import { useI18n } from "vue-i18n";

import IconPhoneMessage from '@/components/icons/IconPhoneMessage.vue';
import IconEmail from '@/components/icons/IconEmail.vue';
import IconSchoolOutline from '~icons/mdi/school-outline';
import IconChatOutline from '~icons/mdi/chat-outline';

const props = withDefaults(defineProps<{
  profile: ProfilzusatzDto,
  directMessageRecipient?: any,
  options: {
    displayLecture: boolean,
    isDialog?: boolean
  }
}>(), {
  options: () => ({
    displayLecture: false,
    isDialog: false
  })
});

const { t } = useI18n();
</script>


<style>
.public-profile-base {
  .public-profile-base-label {
    @apply text-base text-zinc-600;
  }
  .public-profile-base-value {
    @apply underline text-zinc-900 font-semibold;
  }
  &.public-profile-base-is-dialog {
    .public-profile-base-label {
      @apply font-semibold;
    }
    .public-profile-base-value {
      @apply text-primary-600 no-underline;
    }
  }
}


</style>
